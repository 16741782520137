import { colorsUI } from 'constants/colorsUI';
import { shadowUI } from 'constants/shadowUI';

const { light } = colorsUI;
const { shadowModal } = shadowUI;

export const modalDefaultStyles = {
   position: 'absolute' as 'absolute',
   top: '50%',
   left: '50%',
   transform: 'translate(-50%, -50%)',
   width: '100%',
   maxWidth: 'fit-content',
   display: 'flex',
   aliginItems: 'center',
   justifyContent: 'center',
   bgcolor: light,
   borderRadius: '12px',
   boxShadow: 24,
   padding: '24px 24px 32px 24px',
   shadowModal,
   m: 0,
};
