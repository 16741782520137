import { colorsUI } from 'constants/colorsUI';
import { CSSProperties } from 'react';

const { light600 } = colorsUI;

export const TableActionsButtonMoreOptionsPropsStyles: CSSProperties = {
   width: '100%',
   color: light600,
   fontWeight: 700,
   display: 'flex',
   alignItems: 'center',
   gap: '8px',
   justifyContent: 'space-between',
};
