import React, { FormEvent, useState, MouseEvent, ChangeEvent } from 'react';

import useAppDispatch from 'app/dashboard/store/hooks/useAppDispatch';
import { useSignIn } from '../../hooks/useSignIn';
import useGoBack from 'utils/goBack';

import { Box, Typography, SxProps } from '@mui/material';
import FormAuth from 'components/forms/formAuth';
import Logo from '@shared/logo';
import { InputDefault, InputPassword } from '@shared/inputs';
import { ButtonDefault } from '@shared/buttons';
import { LinkDefault } from '@shared/links';
import { Link } from 'react-router-dom';
import { ChexboxDefault } from '@shared/chexboxs';
import { ErrorMessageInput } from '@shared/errorMessage';

import { handleSubmitForm } from './utils/onSubmit';

import { errorsSignIn } from '../../errors';

import {
   boxInputstyles,
   recoveryPasswordStyles,
   chexboxStyles,
   boxButtonStyles,
   buttonSubmitStyles,
} from './style';

import type { ErrorStateSignIn } from '../../schemas/signInSchema';

import { colorsUI } from 'constants/colorsUI';

const { light900, black300, light500, light600, light, transperent } = colorsUI;

export const SignInForms = () => {
   const { handleSignIn, isLoading } = useSignIn();

   const [email, setEmail] = useState('');
   const [password, setPassword] = useState('');
   const [checked, setChecked] = useState(true);
   const [errors, setErrors] = useState<Partial<ErrorStateSignIn> | null>(null);

   const goBack = useGoBack();

   const dispatch = useAppDispatch();

   const onSubmitForm = async (e: FormEvent) => {
      handleSubmitForm({
         e,
         email,
         password,
         checked,
         setErrors,
         setEmail,
         setPassword,
         dispatch,
         errorsSignIn,
         handleSignIn,
      });
   };

   const handleChangeEmail = (e: ChangeEvent<HTMLInputElement>) => {
      setEmail(e.target.value);
   };

   const handleChangePassword = (e: ChangeEvent<HTMLInputElement>) => {
      setPassword(e.target.value);
   };

   const handleGoBackClick = (e: MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault();
      goBack();
   };

   const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
      setChecked(event.target.checked);
   };

   return (
      <FormAuth styles={{ maxWidth: '520px' }} onSubmit={onSubmitForm} gap="24px">
         <Logo style={{ width: '100%', maxWidth: '260px' }} />
         <Box sx={{ width: '100%' }}>
            <Typography sx={{ fontWeight: 700 }} variant="subtitle2">
               Авторизация
            </Typography>
            <Typography sx={{ color: black300, mr: '4px' }} variant="caption">
               Нет аккаунта?
            </Typography>
            <Link style={{ color: light900, fontSize: '14px' }} to="/signup">
               Зарегистрироваться
            </Link>
         </Box>
         <Box sx={boxInputstyles}>
            <InputDefault
               error={errors?.email}
               required
               stylesWrapper={{ width: '100%' }}
               stylesForm={{ width: '100%' }}
               value={email}
               type="email"
               placeholder="Введите E-Mail"
               onChange={handleChangeEmail}
            />
            <InputPassword
               error={errors?.password}
               required
               stylesForm={{ width: '100%' }}
               value={password}
               placeholder="Введите пароль"
               onChange={handleChangePassword}
            />
            {errors?.invalidEmailOfPassword && (
               <ErrorMessageInput message={errors?.invalidEmailOfPassword} />
            )}

            <Link
               style={{ ...recoveryPasswordStyles, margin: '8px 0' }}
               to="/reset-password"
            >
               Забыл пароль?
            </Link>
         </Box>
         <Box sx={chexboxStyles}>
            <ChexboxDefault
               checked={checked}
               onChange={handleChange}
               label="Запомнить меня"
            />
         </Box>

         <Box sx={boxButtonStyles}>
            <LinkDefault
               onClick={handleGoBackClick}
               href="/"
               label="Вернуться назад"
               color={light600}
               colorHover={light900}
               backgroundColor={transperent}
               backgroundColorHover={transperent}
            />
            <ButtonDefault
               sx={buttonSubmitStyles}
               disabled={(email.length === 0 && password.length === 0) || isLoading}
               label="Отправить"
               type="submit"
               color={light}
               backgroundColor={light500}
               backgroundColorHover={light900}
            />
         </Box>
      </FormAuth>
   );
};
