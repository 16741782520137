import { styled } from '@mui/material/styles';
import { colorsUI } from 'constants/colorsUI';

import { StyledLinkAsButtonProps, ButtonDefaultStylesType } from '../types';

const { disabledBackground, disabledText } = colorsUI;

export const StyledLinkAsButton = styled('a')<StyledLinkAsButtonProps>(
   ({ backgroundColor, color, colorHover, backgroundColorHover, disabled }) => ({
      textDecoration: 'none',
      display: 'inline-block',
      padding: '10px 24px',
      borderRadius: '6px',
      backgroundColor: disabled ? disabledBackground : backgroundColor,
      color: disabled ? disabledText : color,
      textAlign: 'center',
      cursor: disabled ? 'not-allowed' : 'pointer',
      transition: 'all .5s',

      fontSize: '1.125rem',
      fontWeight: 700,
      lineHeight: '1.5rem',
      textTransform: 'none',
      ...(!disabled && {
         '&:hover': {
            color: colorHover,
            backgroundColor: backgroundColorHover,
         },
      }),

      '&:focus': {
         outline: 'none',
      },
      pointerEvents: disabled ? 'none' : 'auto',
   }),
);

export const buttonDefaultStyles: ButtonDefaultStylesType = ({
   color,
   colorHover,
   backgroundColor,
   backgroundColorHover,
   disabled,
}) => ({
   backgroundColor: disabled ? disabledBackground : backgroundColor,
   color: disabled ? disabledText : color,
   transition: ' all .5s',
   ...(!disabled && {
      '&:hover': {
         backgroundColor: backgroundColorHover,
         color: colorHover,
         transition: ' all .5s',
      },
   }),

   pointerEvents: disabled ? 'none' : 'auto',
});
