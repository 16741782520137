import * as React from 'react';
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/ru';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { Box } from '@mui/material';

import { calendarStyles } from '../styles';

interface CalendarDefaultProps {
   value: Dayjs; // Текущая дата
   onChange: (newValue: Dayjs) => void; // Функция для обновления даты
}

export const CalendarDefault: React.FC<CalendarDefaultProps> = ({ value, onChange }) => {
   return (
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'ru'}>
         <Box sx={{ position: 'relative' }}>
            <DateCalendar
               showDaysOutsideCurrentMonth
               fixedWeekNumber={5}
               value={value}
               onChange={onChange} // Используем переданную функцию для обновления даты
               sx={calendarStyles()}
            />
         </Box>
      </LocalizationProvider>
   );
};
