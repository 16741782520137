import React, { FC } from 'react';

import { Drawer } from '@mui/material';

import type { DrawerProps } from '../types';

import { drawerDefaultStyles } from '../styles';

export const DrawerDefault: FC<DrawerProps> = ({
   open = true,
   onClose,
   anchor,
   sx,
   variant,
   children,
}) => {
   return (
      <Drawer
         variant={variant}
         open={open}
         onClose={onClose}
         anchor={anchor}
         sx={{
            ...drawerDefaultStyles,
            ...sx,
            '& .MuiDrawer-paper': {
               position: 'static',
            },
         }}
      >
         {children}
      </Drawer>
   );
};
