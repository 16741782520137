import React, { FC } from 'react';
import { ModalDefault } from '../modalDefault';

import { ButtonDefault } from 'shared/buttons';
import { Box, Typography, useTheme } from '@mui/material';

import deletetContentImagePath from '../../../assets/images/deleteContent.png';

import type { ModalDeleteProps } from '../types';

import {
   modalDeleteStyles,
   modalDeleteContentStyles,
   boxButtonStyles,
   imageStyles,
   titleStyles,
   textStyles,
   buttonCloseStyles,
   buttonDeleteStyles,
} from '../styles';

import { colorsUI } from 'constants/colorsUI';

const { light, light900, transperent, danger, dangerActive } = colorsUI;

export const ModalDelete: FC<ModalDeleteProps> = ({
   open,
   onClose,
   onDelete,
   text,
   title,
}) => {
   const theme = useTheme();

   return (
      <ModalDefault
         stylesModalContent={modalDeleteStyles(theme) as object}
         open={open}
         handleClose={onClose}
      >
         <Box sx={modalDeleteContentStyles}>
            <Box
               component="img"
               sx={imageStyles(theme)}
               src={deletetContentImagePath}
               alt="detele menegers"
            />
            <Typography sx={titleStyles}>{title}</Typography>
            <Typography sx={textStyles} variant="body1">
               {text}
            </Typography>
            <Box sx={boxButtonStyles(theme)}>
               <ButtonDefault
                  sx={buttonCloseStyles(theme) as object}
                  backgroundColor={transperent}
                  backgroundColorHover={light900}
                  color={light900}
                  colorHover={light}
                  label="Нет"
                  onClick={onClose}
               />
               <ButtonDefault
                  sx={buttonDeleteStyles(theme) as object}
                  backgroundColor={danger}
                  backgroundColorHover={dangerActive}
                  color={light}
                  label="Да, удалить"
                  onClick={onDelete}
               />
            </Box>
         </Box>
      </ModalDefault>
   );
};
