export const colorsUI = {
   success: '#28AA08',
   successActive: '#299E0B',
   danger: '#E42309',
   dangerActive: '#BF1308',
   light: '#fff',

   accentColor: '#A6062D',

   textFieldBorderColor: '#CBCECF',
   textFieldBorderColorHover: '#878C90',
   placeholderColor: '#A9ADB0',

   disabledText: '#E7E7E6',
   disabledBackground: '#C4C3C0',

   black50: '#E7E7E6',
   black100: '#C4C3C0',
   black200: '#9D9B96',
   black300: '#75736C',
   black400: '#58554D',
   black500: '#3A372D',
   black600: '#343128',
   black700: '#2C2A22',
   black800: '#25231C',
   black900: '#181611',

   light50: '#FFF6E5',
   light100: '#FFEABF',
   light200: '#FFDC95',
   light300: '#FFCD6A',
   light400: '#FFC34A',
   light500: '#FFB82A',
   light600: '#FFB125',
   light700: '#FFA81F',
   light800: '#FFA019',
   light900: '#FF910F',

   transperent: 'transparent',

   errorColor: '#E42309',

   billBoardUserBackgroundColor: 'linear-gradient(180deg, #057B5E 0%, #09A47D 100.01%)',
};
