import { colorsUI } from 'constants/colorsUI';
import { CSSProperties } from 'react';

const { light50, black50, black500 } = colorsUI;

export const tableStyles: CSSProperties = {
   boxShadow: 'none',
   userSelect: 'text',
};

export const tableHeaderStyles: CSSProperties = {
   background: light50,
};

export const tableHeaderCellStyles: CSSProperties = {
   fontWeight: 700,
   color: black500,
   padding: '24px',
};

export const tableRowCellStyles: CSSProperties = {
   color: black500,
   padding: '24px',
};

export const tableCellStyles: CSSProperties = {
   border: `1px solid ${black50}`,
};
