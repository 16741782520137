import React, { FC } from 'react';
import {
   Table,
   TableBody,
   TableCell,
   TableContainer,
   TableHead,
   TableRow,
   Checkbox,
   Paper,
   Typography,
   Grid,
   Box,
} from '@mui/material';
import { TableCheckboxProps } from '../types';
import {
   tableStyles,
   tableHeaderStyles,
   tableHeaderCellStyles,
   tableRowCellStyles,
   tableCellStyles,
} from '../styles';

export const TableCheckboxMobile: FC<TableCheckboxProps> = ({
   columns,
   rows,
   onSelect,
   selected,
}) => {
   const handleSelect = (id: number | string) => {
      onSelect((prevSelected) =>
         prevSelected.includes(id)
            ? prevSelected.filter((item) => item !== id)
            : [...prevSelected, id],
      );
   };

   const isSelected = (id: number | string) => selected.includes(id);

   return (
      <TableContainer sx={tableStyles} component={Paper}>
         {rows.map((row) => (
            <Grid
               container
               key={row.id}
               sx={{ padding: '8px 0', borderBottom: '1px solid #ddd' }}
            >
               <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center' }}>
                  <Checkbox
                     checked={isSelected(row.id as string | number)}
                     onChange={() => handleSelect(row.id as string | number)}
                     sx={{ marginRight: '8px' }}
                  />
                  <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
                     {`Row ID: ${row.id}`}
                  </Typography>
               </Grid>
               {columns.map((column) => (
                  <React.Fragment key={column.id}>
                     <Grid sx={{ ...tableHeaderStyles }} item xs={6}>
                        <Typography sx={tableHeaderCellStyles} variant="subtitle2">
                           {column.label}
                        </Typography>
                     </Grid>
                     <Grid sx={{ ...tableCellStyles }} item xs={6}>
                        {column.renderCell ? (
                           <Box sx={tableRowCellStyles}>{column.renderCell(row)}</Box>
                        ) : (
                           <Typography variant="body1" sx={tableRowCellStyles}>
                              {row[column.id] ?? '-'}
                           </Typography>
                        )}
                     </Grid>
                  </React.Fragment>
               ))}
            </Grid>
         ))}
      </TableContainer>
   );
};
