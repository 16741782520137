import React, { FC } from 'react';
import { Box, useTheme } from '@mui/material';
import { Typography } from '@mui/material';

import type { ProfileHeaderProps } from './type';
import { profileHeaderTitleStyle, profileHeadereStyle } from './style';

export const ProfileHeader: FC<ProfileHeaderProps> = ({ title, children, styles }) => {
   const theme = useTheme();

   const additionalStyles = typeof styles === 'function' ? styles(theme) : styles;

   return (
      <Box
         sx={{
            ...profileHeadereStyle(theme),
            ...(additionalStyles ? additionalStyles : {}),
         }}
      >
         <Typography sx={profileHeaderTitleStyle}>{title}</Typography>
         {children}
      </Box>
   );
};
