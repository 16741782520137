import { useSignUpMutation } from 'app/dashboard/store/api/authApi';

export const useSignUp = () => {
   const [signUp, { isLoading, isSuccess, isError, error }] = useSignUpMutation();
   const handleSignUp = async (email: string) => {
      try {
         const response = await signUp({ email, accept_rules: true }).unwrap();

         return response;
      } catch (err) {
         console.error(err);
         throw err;
      }
   };

   return { handleSignUp, isSuccess, isLoading, isError, error };
};
