import React from 'react';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import { Box, Grid, Typography } from '@mui/material';

import type { TableProps } from '../types';
import {
   tableStyles,
   tableHeaderStyles,
   tableHeaderCellStyles,
   tableCellStyles,
   tableRowCellStyles,
} from '../styles';

export const TableMobile: React.FC<TableProps> = ({ columns, rows }) => {
   return (
      <TableContainer sx={tableStyles} component={Paper}>
         {rows.map((row, rowIndex) => (
            <Grid container key={rowIndex}>
               {columns.map((column) => (
                  <React.Fragment key={column.id}>
                     <Grid sx={{ ...tableHeaderStyles, ...tableCellStyles }} item xs={6}>
                        <Typography sx={tableHeaderCellStyles} variant="subtitle2">
                           {column.label}
                        </Typography>
                     </Grid>
                     <Grid sx={{ ...tableCellStyles }} item xs={6}>
                        {column.renderCell ? (
                           <Box sx={tableRowCellStyles}>{column.renderCell(row)}</Box>
                        ) : (
                           <Typography variant="body1" sx={tableRowCellStyles}>
                              {row[column.id] ?? '-'}
                           </Typography>
                        )}
                     </Grid>
                  </React.Fragment>
               ))}
            </Grid>
         ))}
      </TableContainer>
   );
};
