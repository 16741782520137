import React, { FC, ElementType, ReactNode } from 'react';
import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import type { SxProps, Theme } from '@mui/system';
import type { ColumnWrapperProps } from '../types';
import { columnStyles, columnWrapperStyles } from '../styles';

export const ColumnWrapper: FC<ColumnWrapperProps> = ({
   gap = '24px',
   gapMiddle = '22px',
   gapMobile = '19px',
   children,
   styles,
   maxWidth = '750px',
   component: Component = Box,
   ...other
}) => {
   const theme = useTheme();

   const columnDashboardStyles = columnStyles({
      theme,
      gap,
      gapMiddle,
      gapMobile,
      customStyles: styles,
   });

   return (
      <Box sx={columnWrapperStyles}>
         <Box
            component={Component}
            sx={{ ...columnDashboardStyles, maxWidth: maxWidth }}
            {...other}
         >
            {children}
         </Box>
      </Box>
   );
};
