import { useResetPasswordMutation } from 'app/dashboard/store/api/authApi';

export const useResetPassword = () => {
   const [resetPassword, { isLoading, isSuccess, isError, error }] =
      useResetPasswordMutation();
   const handleResetPassword = async (email: string) => {
      try {
         const response = await resetPassword({ email }).unwrap();
         return response;
      } catch (err) {
         console.error(err);
         throw err;
      }
   };

   return { handleResetPassword, isSuccess, isLoading, isError, error };
};
