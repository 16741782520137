import React, { FC } from 'react';

import { Box } from '@mui/material';

import type { ErrorMessageProps } from '../types';

import { errorMessageStyles } from '../styles';
import { Typography } from '@mui/material';

export const ErrorMessageInput: FC<ErrorMessageProps> = ({
   message,
   stylesErrorMessage,
   stylesInner,
}) => {
   return (
      <Box sx={{ ...stylesInner }}>
         <Typography sx={{ ...stylesErrorMessage, ...errorMessageStyles }}>
            {message}
         </Typography>
      </Box>
   );
};
