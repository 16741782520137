import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const baseUrlDashboard = process.env.REACT_APP_API_DASHBOARD;
const endpointsUrlProfile = process.env.REACT_APP_API_DASHBOARD_PROFILE;

export const profileApi = createApi({
   reducerPath: 'profileApi',
   baseQuery: fetchBaseQuery({
      baseUrl: baseUrlDashboard,
      prepareHeaders: (headers) => {
         headers.set('Content-Type', 'application/json');
         headers.set('Accept', 'application/json');
         const token = document.cookie
            .split('; ')
            .find((row) => row.startsWith('authToken='))
            ?.split('=')[1];
         if (token) {
            headers.set('Authorization', `Bearer ${token}`);
         }
         return headers;
      },
   }),

   endpoints: (builder) => ({
      profile: builder.query({
         query: (data) => ({
            url: endpointsUrlProfile as string,
         }),
      }),
   }),
});

export const { useProfileQuery } = profileApi;
