import React from 'react';

import { Box } from '@mui/material';
import { LinkDefault } from '@shared/links';

import {
   projectsNoneImageStyles,
   projectsNoneStyles,
   projectsNoneButtonStyles,
} from './style';

import imgPathIsNoneProjects from '@assets/images/isNoneProjects.png';
import { ReactComponent as PlusIcon } from '@assets/icons/plus.svg';

export const ProjectsNone = () => {
   return (
      <Box sx={projectsNoneStyles}>
         <img
            style={projectsNoneImageStyles}
            src={imgPathIsNoneProjects}
            alt="у вас нет проектов"
         />
         <LinkDefault
            href="/projects/create-projects"
            sx={projectsNoneButtonStyles as object}
            Icon={PlusIcon}
            label="Создать проект"
         />
      </Box>
   );
};
