import React from 'react';
import { ResetPassword } from '../modules/auth';

import { CenteredContainer } from 'shared/containers';

const RecoveryPasswordPage = () => {
   return (
      <CenteredContainer>
         <ResetPassword />
      </CenteredContainer>
   );
};

export default RecoveryPasswordPage;
