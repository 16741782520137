import React, { FC } from 'react';
import { ButtonDefault } from 'shared/buttons';
import { Box } from '@mui/material';

import { getActiveColor } from 'utils/getActiveColor';

import type { FilterButtonProps } from '../types';

import { innerStyles, buttonStyles } from '../styles';

import { colorsUI } from 'constants/colorsUI';

const { light500, black800 } = colorsUI;

export const FilterButton: FC<FilterButtonProps> = ({
   value,
   arrValues,
   onFilterChange,
}) => {
   return (
      <Box sx={innerStyles}>
         {arrValues.map((el) => (
            <ButtonDefault
               key={el}
               backgroundColor={getActiveColor({ isActive: el == value })}
               color={getActiveColor({
                  isActive: el == value,
                  color: black800,
                  activeColor: light500,
               })}
               onClick={() => onFilterChange(el)}
               label={String(el)}
               sx={buttonStyles}
            />
         ))}
      </Box>
   );
};
