import { Dispatch, FormEvent } from 'react';
import { ErrorStateResetPassword, ResetPasswordData } from '../../../schemas';
import { onSubmitFormRecoveryPassword } from './onSubmitFormRecoveryPassword';

type OnSubmitFormParams = {
   e: FormEvent;
   setShowNotifications: (arg: boolean) => void;
   handleResetPassword: (email: string) => Promise<{ token: string }>;
   setErrors: Dispatch<React.SetStateAction<ErrorStateResetPassword | null>>;
   setEmail: Dispatch<React.SetStateAction<string>>;
   email: string;
};

export const onSubmitForm = ({
   e,
   setShowNotifications,
   handleResetPassword,
   setErrors,
   setEmail,
   email,
}: OnSubmitFormParams) => {
   e.preventDefault();
   const data: ResetPasswordData = { email };
   const result = onSubmitFormRecoveryPassword({ data, setErrors, setEmail });
   if (!result) {
      const response = handleResetPassword(email);

      setShowNotifications(true);
   }
};
