import React from 'react';

import { useMediaQuery, useTheme } from '@mui/material';

import { Box, Grid } from '@mui/material';
import { ButtonArrow } from '@shared/buttons';
import { Typography } from '@mui/material';
import { ProfileHeader } from '@componentsDashBoard/profileHeader';
import { BillboardUser } from '../components/billboards';
import { CardProfile } from '@shared/cards';
import { ActionProjectItem } from '../components/actionProjectItem';
import { LinkDefault } from '@shared/links';

import { ColumnDashboard } from '@shared/column';

import { TableDefault, TableMobile } from '@shared/tables';

import {
   profileCardLinkStyles,
   profileCardTitleleStyles,
   profileCardValideDateStyles,
   profileCardLinkMoreStyles,
} from '../styles';

import { colorsUI } from '@constants/colorsUI';

const { light900, transperent } = colorsUI;

const rows = [
   { name: 'Frozen yoghurt', calories: 159, fat: 6.0, carbs: 24, protein: 4.0 },
   { name: 'Ice cream sandwich', calories: 237, fat: 9.0, carbs: 37, protein: 4.3 },
   { name: 'Eclair', calories: 262, fat: 16.0, carbs: 24, protein: 6.0 },
   { name: 'Cupcake', calories: 305, fat: 3.7, carbs: 67, protein: 4.3 },
   { name: 'Gingerbread', calories: 356, fat: 16.0, carbs: 49, protein: 3.9 },
];

const columns = [
   { id: 'name', label: 'Dessert (100g serving)' },
   { id: 'calories', label: 'Calories' },
   { id: 'fat', label: 'Fat (g)' },
   { id: 'carbs', label: 'Carbs (g)' },
   { id: 'protein', label: 'Protein (g)' },
];

export const ProfileMain = () => {
   const theme = useTheme();
   const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

   return (
      <ColumnDashboard>
         <ProfileHeader title="Главная">
            <ButtonArrow label="Создать новый скрипт" />
         </ProfileHeader>

         <Grid container spacing={3}>
            <Grid item xxl={3} md={6} xs={12}>
               <BillboardUser name="Vetal" />
            </Grid>
            <Grid item xxl={3} md={6} xs={12}>
               <CardProfile
                  titleVariant="body1"
                  headerCard="Активные проекты"
                  tileCard="Дистанционное БФЛ"
                  bodyCard={<ActionProjectItem countClients={250} countProjects={25} />}
                  footerCard={
                     <LinkDefault
                        sx={profileCardLinkStyles}
                        href="/"
                        label="Перейти к проекту"
                        backgroundColorHover={light900}
                     ></LinkDefault>
                  }
               />
            </Grid>
            <Grid item xxl={3} md={5} xs={12}>
               <CardProfile
                  titleVariant="body1"
                  sxTitle={profileCardTitleleStyles}
                  headerCard="Баланс"
                  tileCard="15.00 руб."
                  footerCard={
                     <LinkDefault
                        sx={profileCardLinkStyles}
                        href="/"
                        label="Перейти к проекту"
                        backgroundColorHover={light900}
                     ></LinkDefault>
                  }
               />
            </Grid>
            <Grid item xxl={3} md={7} xs={12}>
               <CardProfile
                  titleVariant="body1"
                  sxTitle={profileCardTitleleStyles}
                  headerCard="Активный тариф"
                  tileCard="Standart"
                  bodyCard={
                     <Typography sx={profileCardValideDateStyles} variant="body2">
                        Действует до: 16.04.2024
                     </Typography>
                  }
                  footerCard={
                     <>
                        <LinkDefault
                           sx={profileCardLinkStyles}
                           href="/"
                           label="Сменить тариф"
                           backgroundColorHover={light900}
                        ></LinkDefault>
                        <LinkDefault
                           sx={profileCardLinkMoreStyles}
                           href="/"
                           label="Подробнее"
                           backgroundColor={transperent}
                        ></LinkDefault>
                     </>
                  }
               />
            </Grid>
         </Grid>

         <Box>
            {isMobile ? (
               <TableMobile columns={columns} rows={rows} />
            ) : (
               <TableDefault columns={columns} rows={rows} />
            )}
         </Box>
      </ColumnDashboard>
   );
};
