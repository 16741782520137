import React, { FC } from 'react';

import { Button } from '@mui/material';

import type { ButtonActionProps } from '../types';

import { colorsUI } from 'constants/colorsUI';
import { buttonActionStyles, buttonActionIconStyles } from '../styles';

const { light900, light500, light } = colorsUI;

export const ButtonAction: FC<ButtonActionProps> = ({
   onClick,
   label = 'click',
   type = 'button',
   color = light,
   colorHover = light,
   backgroundColor = light500,
   backgroundColorHover = light900,
   disabled = false,
   startIcon,
   endIcon,
   sx,
   Icon,
   imgSrc,
   direction = 'before',
   iconStyles = {},
}) => {
   return (
      <Button
         sx={{
            ...buttonActionStyles({
               color,
               colorHover,
               backgroundColor,
               backgroundColorHover,
               disabled,
            }),
            ...sx,
         }}
         type={type}
         onClick={onClick}
         startIcon={startIcon}
         endIcon={endIcon}
      >
         {direction === 'before' && (
            <>
               {imgSrc && <img style={buttonActionIconStyles} src={imgSrc} alt={label} />}
               {Icon && <Icon style={{ ...buttonActionIconStyles, ...iconStyles }} />}
            </>
         )}
         {label}
         {direction === 'after' && (
            <>
               {imgSrc && <img style={buttonActionIconStyles} src={imgSrc} alt={label} />}
               {Icon && <Icon style={{ ...buttonActionIconStyles, ...iconStyles }} />}
            </>
         )}
      </Button>
   );
};
