import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import type { LinkProps } from '../types';
import { linkDefaultStyles } from '../styles';

import { Link as LinkMUI } from '@mui/material';

import { colorsUI } from 'constants/colorsUI';

const { light, light900, light500 } = colorsUI;

export const LinkDefault: FC<LinkProps> = ({
   href,
   label,
   color = light,
   colorHover = light500,
   backgroundColor = light900,
   backgroundColorHover,
   disabled = false,
   styles,
   onClick,
   sx,
   direction = 'before',
   Icon,
}) => {
   const linkBaseStyles = linkDefaultStyles({
      color,
      colorHover,
      backgroundColor,
      backgroundColorHover,
      disabled,
   });

   return (
      <LinkMUI
         component={Link}
         onClick={onClick}
         to={href}
         sx={{ ...linkBaseStyles, ...sx }}
         style={styles}
      >
         {direction === 'before' && <>{Icon && <Icon />}</>}
         {label}
         {direction === 'after' && <>{Icon && <Icon />}</>}
      </LinkMUI>
   );
};
