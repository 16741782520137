import { SxProps, Theme } from '@mui/material/styles';
import { CSSProperties } from 'react';

export const projectsNoneStyles: SxProps<Theme> = (theme: Theme) => ({
   display: 'flex',
   alignItems: 'center',
   flexDirection: 'column',
   gap: '24px',

   [theme.breakpoints.down('md')]: {
      alignItems: 'normal',
   },
});

export const projectsNoneImageStyles: CSSProperties = {
   width: '100%',
   maxWidth: '489px',
   objectFit: 'cover',
};

export const projectsNoneButtonStyles: SxProps<Theme> = {
   padding: '11px 42px',
};
