import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ILine} from "../interfaces/lines";
import {IBlock} from "interfaces/constructor/block";
import {RootState} from "store/store";

interface IProps {
  lines: ILine[];
  blocks: IBlock[];
}

const initialState: IProps = {
  lines: [],
  blocks: [],
};

export const TreeSlice = createSlice({
  name: "TreeSlice",
  initialState,
  reducers: {
    loadLines: (state, action: PayloadAction<ILine[]>) => {
      localStorage.setItem("lines", JSON.stringify(action.payload));
      state.lines = action.payload;
    },

    loadBlocks: (state, action: PayloadAction<IBlock[]>) => {
      state.blocks = action.payload;
    },
  },
});

export const { loadLines, loadBlocks } = TreeSlice.actions;

export const getAllBlocks = (store: RootState) => store.tree.blocks;
export const getAllLines = (store: RootState) => store.tree.lines;

export default TreeSlice.reducer;
