import React, { useState, MouseEvent, useMemo } from 'react';

import { PopupDefault } from '@shared/popups';
import { TableCheckbox, TableCheckboxMobile } from '@shared/tables';
import { ListPopupActionContents } from '@shared/lists';
import { TableActionsButtonMoreOptions } from 'components/tableActions';
import { ReactComponent as IconEdit } from '@assets/icons/edit.svg';
import { ButtonActionsBox, ClientsFilters, ModalClientsFilters } from './components';
import { Box, Theme, useMediaQuery } from '@mui/material';
import { ModalDelete } from '@shared/modals';

import type { ListPopupActionContentItemsProps } from '@shared/lists/types';

import { clientsDataTableStyles } from './style';

export const ClientsData = () => {
   const [selected, setSelected] = useState<(number | string)[]>([]);
   const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
   const [popupIsOpen, setPopupIsOpen] = useState(false);
   const [data, setData] = useState([
      { id: 1, lastName: 'Snow', firstName: 'Jon', age: 35 },
      { id: 2, lastName: 'Lannister', firstName: 'Cersei', age: 42 },
      { id: 3, lastName: 'Lannister', firstName: 'Jaime', age: 45 },
      { id: 4, lastName: 'Stark', firstName: 'Arya', age: 16 },
      { id: 5, lastName: 'Targaryen', firstName: 'Daenerys', age: null },
      { id: 6, lastName: 'Melisandre', firstName: null, age: 150 },
      { id: 7, lastName: 'Clifford', firstName: 'Ferrara', age: 44 },
      { id: 8, lastName: 'Frances', firstName: 'Rossini', age: 36 },
      { id: 9, lastName: 'Roxie', firstName: 'Harvey', age: 65 },
   ]);

   const [openModal, setOpenModal] = useState(false);

   const [openModalClientsFilters, setOpenModalClientsFilters] = useState(false);

   const onClickCloseModal = () => {
      setOpenModal(false);
   };

   const onClickOpenModal = () => {
      setOpenModal(true);
   };

   const onClickDeleteManager = () => {
      console.log(selected);
      console.log(data);
      setData((prev) => prev.filter(({ id }) => !selected.includes(id)));
      setOpenModal(false);
   };

   const onClickCloseModalClientsFilters = () => {
      setOpenModalClientsFilters(false);
   };
   const onClickOpenModalClientsFilters = () => {
      setOpenModalClientsFilters(true);
   };

   const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

   const handleClickPopup = (event: MouseEvent<HTMLElement>, row: string) => {
      setAnchorEl(event.currentTarget);
      setPopupIsOpen(true);
   };

   const popupActionContentItems: ListPopupActionContentItemsProps[] = useMemo(
      () => [
         {
            title: 'Аналитика',
            onClick: () => alert('Аналитика'),
            Icon: IconEdit,
         },
         {
            title: 'Логи',
            onClick: () => alert('Логи'),
            Icon: IconEdit,
         },
         {
            title: 'Уволить',
            onClick: () => alert('Уволить'),
            Icon: IconEdit,
            error: true,
         },
      ],
      [handleClickPopup],
   );

   const handleClosePopup = () => {
      setAnchorEl(null);
      setPopupIsOpen(false);
   };

   const columns = [
      { id: 'firstName', label: 'First Name' },
      { id: 'lastName', label: 'Last Name' },
      {
         id: 'age',
         label: 'Age',
         renderCell: (row: any) => (
            <TableActionsButtonMoreOptions
               text="Запустить"
               onClick={(e) => handleClickPopup(e as MouseEvent<HTMLButtonElement>, row)}
            />
         ),
      },
   ];

   return (
      <>
         <ClientsFilters onClickOpenModal={onClickOpenModalClientsFilters} />

         <ModalDelete
            open={openModal}
            onClose={onClickCloseModal}
            onDelete={onClickDeleteManager}
            title="Удаление клиента"
            text="Вы уверены, что хотите удалить клиентов? 
Все логи и аналитика по клиентам будут удалены"
         />
         <ModalClientsFilters
            onClose={onClickCloseModalClientsFilters}
            open={openModalClientsFilters}
         />

         <PopupDefault
            styles={{ padding: 0 }}
            handleClose={handleClosePopup}
            open={popupIsOpen}
            anchorEl={anchorEl}
         >
            <ListPopupActionContents
               styles={{ padding: 0 }}
               items={popupActionContentItems}
            />
         </PopupDefault>
         <Box sx={clientsDataTableStyles}>
            <ButtonActionsBox
               onClickAppointManager={onClickOpenModal}
               onClickDelete={onClickDeleteManager}
            />
            {isMobile ? (
               <TableCheckboxMobile
                  columns={columns}
                  rows={data}
                  selected={selected}
                  onSelect={setSelected}
               />
            ) : (
               <TableCheckbox
                  columns={columns}
                  rows={data}
                  selected={selected}
                  onSelect={setSelected}
               />
            )}
         </Box>
      </>
   );
};
