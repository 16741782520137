import { SxProps, Theme } from '@mui/material/styles';
import { CSSProperties } from 'react';

import type { ProjectsStylesProps } from './type';

export const projectsStyles: CSSProperties = {
   height: '100%',
};

export const projectsContentStyles = ({
   isNoneProjects,
}: ProjectsStylesProps): SxProps<Theme> => ({
   width: '100%',
   height: '100%',
   display: 'flex',
   alignItems: 'center',
   flexDirection: 'column',
   justifyContent: isNoneProjects ? 'center' : 'start',
});
