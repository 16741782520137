import React, { useState } from 'react';

import { AppBar, Box, Button } from '@mui/material';
import Logo from '@shared/logo';
import { HeaderItem } from './components/headerItem';
import { InstructionsItem } from './components/instructionsItem';
import { ButtonArrow } from '@shared/buttons';
import { DrawerBurgerMenu } from '@shared/drawers';
import { ListLink } from '@shared/lists';
import { ReactComponent as ChangeThemeIcon } from '@assets/icons/changeThemeIcon.svg';
import { ReactComponent as BalanceIcon } from '@assets/icons/balanceIcon.svg';
import { ReactComponent as RateIcon } from '@assets/icons/rateIcon.svg';

import {
   headerLogoStyles,
   headerLogoBox,
   headerStyles,
   headerchangeThemeButton,
   headerNavigationItemStyles,
   headerNavigationItemLinkStyles,
} from './style';

import { colorsUI } from '@constants/colorsUI';

import { navigationRoutes } from '../../routes/navigationRoutes';
import { settingsRoutes } from 'app/dashboard/routes/settingsRoutes';

const { transperent, light900 } = colorsUI;

export const Header = () => {
   const [open, setOpen] = useState(false);

   const handlerDrawerBurgerMenu = () => {
      setOpen(!open);
   };

   return (
      <AppBar position="sticky" sx={headerStyles}>
         <Box sx={headerLogoBox}>
            <Logo style={headerLogoStyles} />
            <Button sx={headerchangeThemeButton}>
               <ChangeThemeIcon />
            </Button>
         </Box>

         <HeaderItem Icon={BalanceIcon} title="Баланс:" label="15.000руб" />
         <HeaderItem Icon={RateIcon} title="Активный тариф:" label="Standart" />
         <Box component="li" sx={headerNavigationItemStyles}>
            <InstructionsItem />
            <ButtonArrow
               label="Выйти"
               backgroundColor={transperent}
               color={light900}
               sx={headerNavigationItemLinkStyles}
            />
         </Box>
         <DrawerBurgerMenu open={open} onClose={handlerDrawerBurgerMenu}>
            <HeaderItem Icon={BalanceIcon} title="Баланс:" label="15.000руб" />
            <HeaderItem Icon={RateIcon} title="Активный тариф:" label="Standart" />

            <InstructionsItem />
            <ButtonArrow
               label="Выйти"
               backgroundColor={transperent}
               color={light900}
               sx={headerNavigationItemLinkStyles}
            />
            <ListLink onClick={handlerDrawerBurgerMenu} items={navigationRoutes} />
            <ListLink onClick={handlerDrawerBurgerMenu} items={settingsRoutes} />
         </DrawerBurgerMenu>
      </AppBar>
   );
};
