import { CSSProperties } from 'react';
import { styled } from '@mui/material/styles';

import { colorsUI } from 'constants/colorsUI';

const { billBoardUserBackgroundColor, light, transperent } = colorsUI;

export const billboardUserStyles: CSSProperties = {
   width: '100%',

   display: 'flex',
   flexDirection: 'column',
   justifyContent: 'space-between',
   alignItems: 'start',
   gap: '64px',
   padding: '24px',
   background: billBoardUserBackgroundColor,
   color: light,
   borderRadius: '8px',
};

export const billboardUserHeaderTitleStyles: CSSProperties = {
   marginBottom: '16px',
};

export const billboardUserFooterLinkStyles: CSSProperties = {
   fontSize: '16px',
   fontWeight: 700,
   lineHeight: '20px',
   color: light,
};

export const billboardUserFooterLinkIvonStyles: CSSProperties = {
   width: '24',
   height: '24',
   fill: '#000',
   transform: 'rotate(180deg)',
};
