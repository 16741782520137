import {
   INavigationItemRoutes,
   INavigationItemRoutesNavBar,
} from '../types/navigationItemRoutes';

import { ReactComponent as HomeIcon } from '../../../assets/icons/homeIcon.svg';
import { ReactComponent as ProjectsIcon } from '../../../assets/icons/projectsIcon.svg';
import { ReactComponent as MenegersIcon } from '../../../assets/icons/menegersIcon.svg';
import { ReactComponent as ClientsIcon } from '../../../assets/icons/clientsIcon.svg';
import { ReactComponent as AnalyticsIcon } from '../../../assets/icons/analyticsIcon.svg';
import { ReactComponent as LogsIcon } from '../../../assets/icons/logsIcon..svg';

export const navigationRoutes: INavigationItemRoutes[] = [
   {
      path: '/',
      message: 'Главная',
   },
   {
      path: '/projects',
      message: 'Проекты',
   },
   {
      path: '/projects/create-projects',
      message: 'Создание проекта',
   },
   {
      path: '/managers',
      message: 'Мои менеджеры',
   },
   {
      path: '/managers/create-managers',
      message: 'Добавление менеджера',
   },
   {
      path: '/clients',
      message: 'клиенты',
   },
   {
      path: '/analytics',
      message: 'аналитика',
   },
   {
      path: '/logs',
      message: 'логи',
   },
];

export const navigationRoutesNavbar: INavigationItemRoutesNavBar[] = [
   {
      path: '/',
      message: 'Главная',
      Icon: HomeIcon,
   },
   {
      path: '/projects',
      message: 'Проекты',
      Icon: ProjectsIcon,
   },

   {
      path: '/managers',
      message: 'менеджеры',
      Icon: MenegersIcon,
   },
   {
      path: '/clients',
      message: 'клиенты',
      Icon: ClientsIcon,
   },
   {
      path: '/analytics/fff/ggg',
      message: 'аналитика',
      Icon: AnalyticsIcon,
   },
   {
      path: '/logs',
      message: 'логи',
      Icon: LogsIcon,
   },
];
