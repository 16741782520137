import React from 'react';

import DashboardRouter from './components/dashboardRouter';
import { Provider as DashboardProvider } from 'react-redux';
import store from './store';

const Dashboard = () => {
   return (
      <DashboardProvider store={store}>
         <DashboardRouter />
      </DashboardProvider>
   );
};

export default Dashboard;
