import { Dispatch,SetStateAction  } from 'react';
import { ZodError } from 'zod';

import { getErrorsAuth } from '../../../utils';

import type {ResetPasswordData, ErrorStateResetPassword} from '../../../schemas';
import { resetPasswordSchema } from '../../../schemas';

import { errorsResetPassword } from '../../../errors';


type onSubmitFormSignInParams = {
    data: ResetPasswordData;
    setErrors: Dispatch<SetStateAction<ErrorStateResetPassword | null>>
    setEmail: Dispatch<SetStateAction<string>>
};

export const onSubmitFormRecoveryPassword = ({data, setErrors, setEmail} :onSubmitFormSignInParams):ZodError | undefined =>{
  
    try {
        resetPasswordSchema.parse(data)
        setErrors(null)
        alert(data)
        setEmail("")
    }
    catch(err){
        console.log(err)
        if(err instanceof ZodError){
            const resErrors = getErrorsAuth(err.errors, resetPasswordSchema)
            setErrors(resErrors)
        } else {
            console.error("Низвестная ошибка");
            setErrors({ unknownError: errorsResetPassword.unknownError })
        }

        return err as ZodError
    }
}