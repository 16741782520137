import { Dispatch, SetStateAction } from 'react';
import { SignInData, ErrorStateSignIn } from '../../../schemas';
import { signInSchema } from '../../../schemas';
import { ZodError } from 'zod';

import { getErrorsAuth } from '../../../utils';

import { errorsSignUp } from '../../../errors';

type validateSignInFormParams = {
   data: SignInData;
   setErrors: Dispatch<SetStateAction<ErrorStateSignIn | null>>;
   setEmail: Dispatch<SetStateAction<string>>;
   setPassword: Dispatch<SetStateAction<string>>;
};

export const validateSignInForm = ({
   data,
   setErrors,
   setEmail,
   setPassword,
}: validateSignInFormParams): ZodError | undefined => {
   try {
      signInSchema.parse(data);
      setErrors(null);
      alert(data);
      setPassword('');
      setEmail('');
   } catch (err) {
      if (err instanceof ZodError) {
         const resErrors = getErrorsAuth(err.errors, signInSchema);
         console.log(resErrors);
         setErrors(resErrors);
      } else {
         console.error('Низвестная ошибка');
         setErrors({ unknownError: errorsSignUp.unknownError });
      }

      return err as ZodError;
   }
};
