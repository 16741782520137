import React, { FC } from 'react';
import {
   FormControl,
   IconButton,
   Input,
   InputAdornment,
   InputLabel,
   OutlinedInput,
   Typography,
} from '@mui/material';
import { IconEye, IconsEyeClose } from './icons';
import { CustomOutlinedInput } from '../styles';

import type { InputPasswordType } from '../types';

import { colorsUI } from 'constants/colorsUI';

const { black200, errorColor } = colorsUI;

export const InputPassword: FC<InputPasswordType> = ({
   label,
   value,
   onChange,
   required = false,
   placeholder = 'placeholder',
   error = false,
   disabled = false,
   stylesWrapper,
   stylesForm,
}) => {
   const [showPassword, setShowPassword] = React.useState(false);

   const handleClickShowPassword = () => {
      if (!disabled) {
         setShowPassword(!showPassword);
      }
   };

   const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
   };

   const handleMouseUpPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
   };

   return (
      <FormControl sx={stylesForm} variant="standard">
         <CustomOutlinedInput
            sx={{
               '& .MuiOutlinedInput-notchedOutline': {
                  border: 'none',
               },
            }}
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            disabled={disabled}
            style={stylesForm}
            required={required}
            type={showPassword ? 'text' : 'password'}
            endAdornment={
               <InputAdornment position="end">
                  <IconButton
                     aria-label="toggle password visibility"
                     onClick={handleClickShowPassword}
                     onMouseDown={handleMouseDownPassword}
                     onMouseUp={handleMouseUpPassword}
                  >
                     {showPassword ? (
                        <IconsEyeClose stroke={black200} />
                     ) : (
                        <IconEye stroke={black200} />
                     )}
                  </IconButton>
               </InputAdornment>
            }
         />
         {label && <Typography>{label}</Typography>}
         {error && <Typography sx={{ color: errorColor }}>{error}</Typography>}
      </FormControl>
   );
};
