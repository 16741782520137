import { SxProps, Theme } from '@mui/material/styles';
import { colorsUI } from 'constants/colorsUI';

const {
   textFieldBorderColor,
   textFieldBorderColorHover,
   placeholderColor,
   light800,
   black500,
   black50,
   black100,
   errorColor,
} = colorsUI;

export const inputStyles = (hasError: boolean, disabled: boolean): SxProps<Theme> => ({
   '& .MuiOutlinedInput-root': {
      '& input': {
         fontSize: '1rem',
         color: black500,
      },

      '& fieldset': {
         transition: 'all .5s',
         borderRadius: '4px',
         border: `1px solid ${hasError ? errorColor : textFieldBorderColor}`,
      },

      '& .MuiInputBase-input': {
         padding: '12px 16px 8px 16px',
         fontSize: '1rem',
         backgroundColor: disabled ? black50 : '',
         color: disabled ? black100 : '',
         borderColor: disabled ? black100 : '',
      },
      '& .MuiInputBase-input::placeholder': {
         color: placeholderColor,
      },

      '&:hover fieldset': {
         borderColor: hasError ? errorColor : textFieldBorderColorHover,
      },

      '&.Mui-focused fieldset': {
         border: `1px solid ${hasError ? errorColor : light800}`,
      },
   },
});
