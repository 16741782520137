import Admin from 'app/admin';
import LK from 'app/dashboard';

export const useComponentForHost = () => {
   const hostname = window.location.hostname;
   console.log(hostname);

   switch (hostname) {
      case 'dashboard.script-project.local':
         return LK;
      case 'admin.script-project.local':
         return Admin;
      default:
         return () => <div>Unknown domain</div>;
   }
};
