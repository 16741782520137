import React from 'react';
import { SignInForms } from '../modules/auth';
import { CenteredContainer } from 'shared/containers';

const SignIn = () => {
   return (
      <CenteredContainer>
         <SignInForms />
      </CenteredContainer>
   );
};

export default SignIn;
