import { CSSProperties } from 'react';

import { colorsUI } from 'constants/colorsUI';

const { light } = colorsUI;

export const drawerBurgerStyles: CSSProperties = {
   height: '100dvh',
   display: 'flex',
   flexDirection: 'column',
   alignItems: 'center',
};

export const drawerBurgerInnerStyles: CSSProperties = {
   width: '100%',
   height: '100%',
   display: 'flex',
   flexDirection: 'column',
   gap: '24px',
   padding: '30px 20px',
};
