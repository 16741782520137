import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useProfileQuery } from 'app/dashboard/store/api/profileApi';

import useAppDispatch from 'app/dashboard/store/hooks/useAppDispatch';
import { login } from 'app/dashboard/store/slices/auth/authSlice';

import useAppSelector from 'app/dashboard/store/hooks/useAppSelector';

export const useAuth = () => {
   const dispatch = useAppDispatch();
   const { isAuthenticated } = useAppSelector((state) => state.auth);

   const navigate = useNavigate();
   const { data, error, isError, isLoading, isSuccess } = useProfileQuery({
      skip: !isAuthenticated,
      refetchOnMountOrArgChange: true,
   });

   useEffect(() => {
      if (isLoading) return;
      if (error) {
         console.error('Ошибка при загрузке профиля:', error);
         navigate('/signin');
         return;
      }

      if (isSuccess) {
         dispatch(login());
      }
   }, [data, error, isLoading, isSuccess]);

   return { isLoading, error, isError, data };
};
