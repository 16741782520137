import { z } from 'zod';

import { errorsSignIn } from '../errors';

const { invalidEmai, invalidEmailOfPassword } = errorsSignIn;

export const signInSchema = z
   .object({
      email: z.string().email({ message: invalidEmai }),
      password: z.string(),
   })
   .strip();

export type SignInData = z.infer<typeof signInSchema> & { remember_me: boolean };
export type ErrorStateSignIn = Partial<SignInData> & {
   unknownError?: string;
   invalidEmailOfPassword?: string;
};
