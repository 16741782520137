import React from 'react';

import imagePath from '@assets/images/menegers.png';
import { Typography } from '@mui/material';
import { LinkDefault } from '@shared/links';

import { ReactComponent as PlusIcon } from '@assets/icons/plus.svg';

import styles from './styles/style.module.css';
import { managersNoneButtonStyles, managersNoneTypographyStyles } from './styles/styles';

const { box, image } = styles;

export const MenegersNone = () => {
   return (
      <div className={box}>
         <img src={imagePath} className={image} alt="not menegers" />
         <Typography sx={managersNoneTypographyStyles} variant="body1">
            У вас пока нет добавленных менеджеров
         </Typography>
         <LinkDefault
            href="/projects/create-projects"
            sx={managersNoneButtonStyles as object}
            Icon={PlusIcon}
            label="Создать проект"
         />
      </div>
   );
};
