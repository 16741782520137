import React, { useState } from 'react';

import { useMediaQuery, useTheme } from '@mui/material';

import { Box } from '@mui/material';
import { FiltersDefault, FilterButton } from '@shared/filters';

import { projectsDataStyles, projectsDataFilterStyles } from './styles';
import { TableDefault, TableMobile } from '@shared/tables';

const arrValues = ['новые', 'старый'];
const arrValuesButton = ['Личные проекты', 'Я менеджер'];

const rows = [
   { name: '1 Frozen yoghurt', calories: 159, fat: 6.0, carbs: 24, protein: 4.0 },
   { name: '1 Ice cream sandwich', calories: 237, fat: 9.0, carbs: 37, protein: 4.3 },
   { name: '1 Eclair', calories: 262, fat: 16.0, carbs: 24, protein: 6.0 },
   { name: '1 Cupcake', calories: 305, fat: 3.7, carbs: 67, protein: 4.3 },
   { name: '1 Gingerbread', calories: 356, fat: 16.0, carbs: 49, protein: 3.9 },
];

const columns = [
   { id: 'name', label: 'Dessert (100g serving)' },
   { id: 'calories', label: 'Calories' },
   { id: 'fat', label: 'Fat (g)' },
   { id: 'carbs', label: 'Carbs (g)' },
   { id: 'protein', label: 'Protein (g)' },
];

export const ProjectsData = () => {
   const [valueFilter, setValueFilter] = useState<string | number>(arrValues[0]);
   const [valueFilterButton, setValueFilterButton] = useState<string | number>(
      arrValuesButton[0],
   );

   const theme = useTheme();
   const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

   const onFilterChange = (value: string | number) => {
      setValueFilter(value);
   };

   const onFilterButtonChange = (value: string | number) => {
      setValueFilterButton(value);
   };

   return (
      <Box sx={projectsDataStyles}>
         <Box sx={projectsDataFilterStyles}>
            <FiltersDefault
               onFilterChange={onFilterChange}
               value={valueFilter}
               arrValues={arrValues}
            />

            <FilterButton
               value={valueFilterButton}
               onFilterChange={onFilterButtonChange}
               arrValues={arrValuesButton}
            />
         </Box>

         {isMobile ? (
            <TableMobile columns={columns} rows={rows} />
         ) : (
            <TableDefault columns={columns} rows={rows} />
         )}
      </Box>
   );
};
