import React, { FC } from 'react';
import {
   Table,
   TableBody,
   TableCell,
   TableContainer,
   TableHead,
   TableRow,
   Paper,
   Typography,
} from '@mui/material';
import { ChexboxDefault } from 'shared/chexboxs';
import { TableCheckboxProps } from '../types';
import {
   tableStyles,
   tableHeaderStyles,
   tableHeaderCellStyles,
   tableRowCellStyles,
   tableCellStyles,
   tableCheckBoxCellWithCheckboxStyles,
   tableCheckBoxCellWithoutCheckboxStyles,
   tableCheckBoxRowCellStyles,
   tableCheckBoxSelectedRowStyles,
} from '../styles';

import { colorsUI } from 'constants/colorsUI';

const { light100 } = colorsUI;

export const TableCheckbox: FC<TableCheckboxProps> = ({
   columns,
   rows,
   onSelect,
   selected,
}) => {
   const handleSelect = (id: number | string) => {
      onSelect((prevSelected) =>
         prevSelected.includes(id)
            ? prevSelected.filter((item) => item !== id)
            : [...prevSelected, id],
      );
   };

   const isSelected = (id: number | string) => selected.includes(id);

   return (
      <Paper sx={{ boxShadow: 'none' }}>
         <TableContainer sx={{ ...tableStyles }}>
            <Table sx={{ minWidth: 650 }}>
               <TableHead>
                  <TableRow sx={tableHeaderStyles}>
                     <TableCell
                        padding="checkbox"
                        sx={{
                           ...tableCellStyles,
                           ...tableCheckBoxCellWithCheckboxStyles,
                           padding: 0,
                           border: 'none',
                        }}
                     >
                        <ChexboxDefault
                           styles={{ marginLeft: '5px' }}
                           checked={selected.length === rows.length}
                           onChange={() => {
                              if (selected.length === rows.length) {
                                 onSelect([]);
                              } else {
                                 onSelect(rows.map((row) => row.id as number));
                              }
                           }}
                        />
                     </TableCell>
                     {columns.map((column) => (
                        <TableCell sx={tableHeaderCellStyles} key={column.id}>
                           <Typography variant="subtitle2" sx={{ fontWeight: 700 }}>
                              {column.label}
                           </Typography>
                        </TableCell>
                     ))}
                  </TableRow>
               </TableHead>
               <TableBody>
                  {rows.map((row) => (
                     <TableRow
                        key={row.id}
                        selected={isSelected(row.id as number | string)}
                        sx={{
                           ...tableRowCellStyles,
                           ...(isSelected(row.id as number | string)
                              ? { ...tableCheckBoxSelectedRowStyles }
                              : {}),
                           transition: 'all .5s',
                           '&:hover': {
                              backgroundColor: light100,
                           },
                        }}
                     >
                        <TableCell
                           padding="checkbox"
                           sx={{
                              ...tableCellStyles,
                              ...tableCheckBoxCellWithoutCheckboxStyles,
                           }}
                        >
                           <ChexboxDefault
                              checked={isSelected(row.id as number | string)}
                              onChange={() => handleSelect(row.id as number | string)}
                           />
                        </TableCell>
                        {columns.map((column) => (
                           <TableCell sx={tableCheckBoxRowCellStyles} key={column.id}>
                              {column.renderCell ? (
                                 column.renderCell(row)
                              ) : (
                                 <Typography variant="body1">
                                    {row[column.id] || '-'}
                                 </Typography>
                              )}
                           </TableCell>
                        ))}
                     </TableRow>
                  ))}
               </TableBody>
            </Table>
         </TableContainer>
      </Paper>
   );
};
