import React, { FC } from 'react';

import { Box, TextField, Typography } from '@mui/material';
import { inputStyles } from '../styles';

import type { InputType } from '../types';

import { colorsUI } from 'constants/colorsUI';

const { errorColor } = colorsUI;

export const InputDefault: FC<InputType> = ({
   label,
   type = 'text',
   value,
   onChange,
   required = false,
   placeholder = 'placeholder',
   error,
   disabled = false,
   stylesWrapper,
   stylesForm,
   labelStyles,
   labelVariant = 'body1',
}) => {
   return (
      <Box sx={stylesWrapper}>
         <TextField
            sx={inputStyles(!!error, disabled)}
            type={type}
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            variant="outlined"
            disabled={disabled}
            style={stylesForm}
            required={required}
         />
         {label && (
            <Typography variant={labelVariant} sx={labelStyles}>
               {label}
            </Typography>
         )}
         {error && <Typography sx={{ color: errorColor }}>{error}</Typography>}
      </Box>
   );
};
