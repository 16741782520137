import React, { FC, FormEvent, useState } from 'react';

import { ModalDefault } from '@shared/modals';
import { ColumnWrapper } from '@shared/column';
import { InputDefault } from '@shared/inputs';
import { Box, Typography, useTheme } from '@mui/material';
import { SelectDefault } from '@shared/selects';
import { ButtonDefault } from '@shared/buttons';

import handleInput from '@utils/handleInput';
import handleSelect from '@utils/handleSelect';
import { onSubmitClientsAddForm } from './utils';

import type { ClientsAddFormErrorsType, ModalClientAddProps } from './types';

import imgAddPath from '@assets/images/add.png';

import {
   modalStyles,
   modalClientAddStyles,
   modalClientsAddInputBox,
   modalClientAddImgStyles,
   modalClientsAddInputsTitle,
   modalClientAddSelectStyles,
   modalClientAddLabelStyles,
   modalClientAddInputStyles,
   modalClientAddButtonBoxStyles,
   modalClientsAddButtonStyles,
   modalClientAddCloseIconStyles,
} from './style';

import { ReactComponent as IconKrest } from '@assets/icons/deleteKrest.svg';

const managersArr = ['первый', 'второй', 'третий'];

export const ModalClientAdd: FC<ModalClientAddProps> = ({ open, onClose }) => {
   const [name, setName] = useState<string>('');
   const [tel, setTel] = useState<string>('');
   const [email, setEmail] = useState<string>('');
   const [product, setProduct] = useState<string>('');
   const [manager, setManager] = useState('');
   const [errors, setErrors] = useState<ClientsAddFormErrorsType>({
      name: '',
      tel: '',
      email: '',
   });

   const theme = useTheme();

   const onSubmitForm = async (e: FormEvent) => {
      const data = {
         name,
         tel,
         email,
         product,
         manager,
      };
      onSubmitClientsAddForm({ e, onClose, setErrors, data });
   };

   return (
      <ModalDefault stylesModalContent={modalStyles} open={open} handleClose={onClose}>
         <Box onClick={onClose} sx={modalClientAddCloseIconStyles()}>
            <IconKrest />
         </Box>
         <ColumnWrapper
            component="form"
            onSubmit={onSubmitForm}
            styles={modalClientAddStyles}
            maxWidth="464px"
         >
            <Box
               sx={modalClientAddImgStyles}
               component="img"
               src={imgAddPath}
               alt="Добавить клиента"
            />
            <Typography variant="h2" sx={modalClientsAddInputsTitle(theme)}>
               Добавить клиента
            </Typography>
            <Box sx={modalClientsAddInputBox}>
               <InputDefault
                  required
                  value={name}
                  onChange={(e) => handleInput(e, setName)}
                  placeholder="ФИО Клиента*"
                  label="Можно использовать, как константу в редакторе скриптов"
                  labelVariant="overline"
                  stylesWrapper={modalClientAddInputStyles}
                  labelStyles={modalClientAddLabelStyles}
                  error={errors.name}
               />
               <InputDefault
                  required
                  type="tel"
                  value={tel}
                  onChange={(e) => handleInput(e, setTel)}
                  placeholder="Телефон клиента*"
                  label="Можно использовать, как константу в редакторе скриптов"
                  labelVariant="overline"
                  stylesWrapper={modalClientAddInputStyles}
                  labelStyles={modalClientAddLabelStyles}
                  error={errors.tel}
               />
               <InputDefault
                  type="email"
                  value={email}
                  onChange={(e) => handleInput(e, setEmail)}
                  placeholder="Электронная почта клиента"
                  label="Можно использовать, как константу в редакторе скриптов"
                  labelVariant="overline"
                  stylesWrapper={modalClientAddInputStyles}
                  labelStyles={modalClientAddLabelStyles}
                  error={errors.email}
               />
               <InputDefault
                  value={product}
                  onChange={(e) => handleInput(e, setProduct)}
                  placeholder="Продукт"
                  label="Можно использовать, как константу в редакторе скриптов"
                  labelVariant="overline"
                  stylesWrapper={modalClientAddInputStyles}
                  labelStyles={modalClientAddLabelStyles}
               />
               <SelectDefault
                  styles={modalClientAddSelectStyles}
                  value={manager}
                  onChange={(e) => handleSelect<string>(e, setManager)}
                  arrValues={managersArr}
               />
            </Box>

            <Box sx={modalClientAddButtonBoxStyles}>
               <ButtonDefault
                  onClick={onSubmitForm}
                  type="submit"
                  label="Добавить контакт"
                  sx={modalClientsAddButtonStyles(theme) as object}
               />
               <ButtonDefault label="Выйти без сохранения" />
            </Box>
         </ColumnWrapper>
      </ModalDefault>
   );
};
