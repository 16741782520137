import { Dispatch, FormEvent, SetStateAction } from 'react';
import { ErrorStateSignUp, SignUpData } from '../../../schemas';
import { validateSignUpForm } from './validateSignUpForm';
import { SignUppiResponse } from '../type';

type SignUpSubmitParams = {
   e: FormEvent;
   email: string;
   setErrors: Dispatch<SetStateAction<ErrorStateSignUp | null>>;
   setShowNotifications: Dispatch<SetStateAction<boolean>>;
   errorsSignUp: ErrorStateSignUp;
   handleSignUp: (email: string) => Promise<{ token: string }>;
};

export const onSubmitForm = async ({
   e,
   email,
   setErrors,
   setShowNotifications,
   errorsSignUp,
   handleSignUp,
}: SignUpSubmitParams) => {
   e.preventDefault();

   const data: SignUpData = { email };
   const result = validateSignUpForm({ data, setErrors });
   console.log(result);

   if (!result) {
      try {
         const response = await handleSignUp(data.email);
         setShowNotifications(true);
      } catch (err) {
         setErrors({ emailAlreadyExists: errorsSignUp.emailAlreadyExists });
      }
   }
};
