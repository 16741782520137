import { useSignInMutation } from 'app/dashboard/store/api/authApi';
import { useNavigate } from 'react-router-dom';

type handleSignInParams = {
   email: string;
   password: string;
   remember_me: boolean;
};

export const useSignIn = () => {
   const [signIn, { isLoading, isSuccess, isError, error }] = useSignInMutation();
   const navigate = useNavigate();

   const handleSignIn = async (data: handleSignInParams) => {
      const { email, password, remember_me } = data;

      try {
         const response = await signIn({ email, password, remember_me }).unwrap();
         navigate('/');
         return response;
      } catch (err) {
         console.error(err);
         throw err;
      }
   };

   return { handleSignIn, isSuccess, isLoading, isError, error };
};
