import React, { FC } from 'react';

import { Box, Button, Typography } from '@mui/material';
import { ButtonMore } from 'shared/buttons';

import { TableActionsButtonMoreOptionsPropsStyles } from './style';

import type { TableActionsButtonMoreOptionsProps } from './type.js';

import { ReactComponent as IconMore } from '../../../assets/icons/more-options-icon.svg';

export const TableActionsButtonMoreOptions: FC<TableActionsButtonMoreOptionsProps> = ({
   onClick,
   text,
   styles = {},
}) => {
   return (
      <Button
         onClick={onClick}
         sx={{ ...TableActionsButtonMoreOptionsPropsStyles, ...styles }}
      >
         <Typography sx={{ ...styles }} variant="body2">
            {text}
         </Typography>

         <IconMore />
      </Button>
   );
};
