import React, { FC } from 'react';

import { Button, SxProps } from '@mui/material';

import type { ButtonBurgerProps } from '../types';

import { buttonBurgerStyles } from '../styles';

export const ButtonBurger: FC<ButtonBurgerProps> = ({ isOpen, setIsOpen, sx = {} }) => {
   return (
      <Button
         sx={(theme) => ({
            ...buttonBurgerStyles({ open: isOpen }, theme), // Передаем тему для работы с брейкпоинтами
            ...sx,
         })}
         onClick={setIsOpen}
      >
         <div />
         <div />
         <div />
      </Button>
   );
};
