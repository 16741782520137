import React, { FC, ReactNode } from 'react';

import FormControl from '@mui/material/FormControl';
import { SelectChangeEvent, Typography } from '@mui/material';
import ArrowSelectIcon from '../icons/arrowSelect';

import type { SelectProps } from '../types';
import {
   CustomSelect,
   CustomMenuItemCheckBox,
   CustomOutlinedInput,
   CustomMenuItem,
} from '../styles';

import { colorsUI } from 'constants/colorsUI';

const { black200 } = colorsUI;

export const SelectDefault: FC<SelectProps> = ({
   value,
   onChange,
   disable = false,
   arrValues,
   placeholder = 'placeholder',
   itemHeight = 48,
   itemPaddingTop = 8,
   styles,
   labelId = 'custom-select-label',
   id = 'custom-select',
   label,
   labelStyles,
   labelVariant = 'body2',
}) => {
   const MenuProps = {
      PaperProps: {
         style: {
            maxHeight: itemHeight * 4.5 + itemPaddingTop,
            width: 250,
         },
      },
   };

   return (
      <FormControl sx={{ ...styles }}>
         <CustomSelect
            labelId={labelId}
            id={id}
            value={value}
            onChange={
               onChange as (event: SelectChangeEvent<unknown>, child: ReactNode) => void
            }
            input={<CustomOutlinedInput disabled={disable} />}
            MenuProps={MenuProps}
            renderValue={(selected: any) => {
               if (selected === '') {
                  return (
                     <Typography sx={{ color: black200 }} variant="body2">
                        {placeholder}
                     </Typography>
                  );
               }
               return selected;
            }}
            IconComponent={ArrowSelectIcon}
            disabled={disable}
            displayEmpty
         >
            {arrValues.map((option) => (
               <CustomMenuItem key={option} value={option} disabled={disable}>
                  {option}
               </CustomMenuItem>
            ))}
         </CustomSelect>
         {label && (
            <Typography variant={labelVariant} style={labelStyles}>
               {label}
            </Typography>
         )}
      </FormControl>
   );
};
