import { CSSProperties } from 'react';

import { colorsUI } from 'constants/colorsUI';
import { SxProps } from '@mui/material';

const { light, light50, light100, black50, black500 } = colorsUI;

export const tableCheckBoxCellWithCheckboxStyles: CSSProperties = {
   background: light,
};

export const tableCheckBoxCellWithoutCheckboxStyles: CSSProperties = {
   border: 'none',
   borderRight: `1px solid ${black50}`,
};

export const tableCheckBoxRowCellStyles: CSSProperties = {
   color: black500,
   padding: '24px',
   border: `1px solid ${black50}`,
};

export const tableCheckBoxSelectedRowStyles: SxProps = {
   backgroundColor: `${light50} !important`,
};
