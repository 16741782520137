import React, { FC } from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';

import type { ModalProps } from '../types';

import { modalDefaultStyles } from '../styles';

export const ModalDefault: FC<ModalProps> = ({
   children,
   open,
   handleClose,
   stylesModal,
   stylesModalContent,
}) => {
   return (
      <Modal
         aria-labelledby="transition-modal-title"
         aria-describedby="transition-modal-description"
         open={open}
         onClose={handleClose}
         closeAfterTransition
         sx={stylesModal}
         slots={{ backdrop: Backdrop }}
         slotProps={{
            backdrop: {
               timeout: 500,
            },
         }}
      >
         <Fade in={open}>
            <Box sx={{ ...modalDefaultStyles, ...stylesModalContent }}>{children}</Box>
         </Fade>
      </Modal>
   );
};
