import React, { FC } from 'react';
import Button from '@mui/material/Button';
import type { ButtonDefaultProps } from '../types';

import { StyledLinkAsButton, buttonDefaultStyles } from '../styles';

import { colorsUI } from 'constants/colorsUI';

const { light900, light500, light } = colorsUI;

export const ButtonDefault: FC<ButtonDefaultProps> = ({
   onClick,
   href,
   label = 'click',
   type = 'button',
   color = light,
   colorHover = light,
   backgroundColor = light500,
   backgroundColorHover = light900,
   disabled = false,

   startIcon,
   endIcon,
   sx,
}) => {
   return (
      <>
         {href ? (
            <StyledLinkAsButton
               disabled={disabled}
               color={color}
               colorHover={colorHover}
               backgroundColorHover={backgroundColorHover}
               backgroundColor={backgroundColor}
               href={disabled ? undefined : href}
            >
               {label}
            </StyledLinkAsButton>
         ) : (
            <Button
               sx={{
                  ...buttonDefaultStyles({
                     color,
                     colorHover,
                     backgroundColor,
                     backgroundColorHover,
                     disabled,
                  }),
                  ...sx,
               }}
               type={type}
               onClick={onClick}
               startIcon={startIcon && startIcon}
               endIcon={endIcon && endIcon}
            >
               {label}
            </Button>
         )}
      </>
   );
};
