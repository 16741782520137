import React, { FC } from 'react';

import { InputSearchTypes } from '../types';
import { Box, InputAdornment, TextField, Typography, useTheme } from '@mui/material';

import { inputSearchStyles } from '../styles';

import { ReactComponent as IconSearch } from '../../../assets/icons/search.svg';

export const InputSearch: FC<InputSearchTypes> = ({
   value,
   inputRef,
   onChange,
   label,
   labelStyles,
   disabled = false,
   labelVariant = 'body1',
   stylesForm,
   stylesWrapper,
   placeholder = 'поиск',
}) => {
   const theme = useTheme();

   return (
      <Box sx={stylesWrapper}>
         <TextField
            sx={{ ...inputSearchStyles(theme, disabled), ...(stylesForm as object) }}
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            variant="outlined"
            disabled={disabled}
            ref={inputRef}
            InputProps={{
               startAdornment: (
                  <InputAdornment position="start">
                     <IconSearch />
                  </InputAdornment>
               ),
            }}
         />
         {label && (
            <Typography variant={labelVariant} sx={labelStyles}>
               {label}
            </Typography>
         )}
      </Box>
   );
};
