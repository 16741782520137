import React, { ChangeEvent, useState } from 'react';
import { Box, SelectChangeEvent } from '@mui/material';

import { InputDefault } from '@shared/inputs';
import { SelectDefault } from '@shared/selects';
import { ButtonDefault } from '@shared/buttons';

import {
   labelStyles,
   formCreateProjectsStyles,
   inputStyles,
   formBoxButtonStyles,
} from './style';

import { colorsUI } from 'constants/colorsUI';

const { light, light900 } = colorsUI;

const selectValue = ['Option 1', 'Option 2', 'Option 3'];

export const FormCreateProjects = () => {
   const [name, setName] = useState('');
   const [sphere, setSphere] = useState('');
   const [direction, setDirection] = useState('');
   const [involvement, setInvolvement] = useState('');

   const onClickAddDraft = () => {
      alert('draft');
   };

   const onClickEdit = () => {
      alert('go to edit!');
   };

   const onChangeName = (e: ChangeEvent<HTMLInputElement>) => {
      setName(e.target.value);
   };

   const handleSubmit = (e: React.FormEvent) => {
      e.preventDefault();
   };

   const handleSelectSphere = (event: SelectChangeEvent<string>) => {
      setSphere(event.target.value);
   };
   const handleSelectDirection = (event: SelectChangeEvent<string>) => {
      setDirection(event.target.value);
   };
   const handleSelectInvolvement = (event: SelectChangeEvent<string>) => {
      setInvolvement(event.target.value);
   };
   return (
      <form style={formCreateProjectsStyles} onSubmit={handleSubmit}>
         <InputDefault
            stylesWrapper={inputStyles}
            onChange={onChangeName}
            value={name}
            labelStyles={labelStyles}
            labelVariant="overline"
            label="Необходимо для внутреннего использования, название видят и ваши менеджеры, у которых есть к нему доступ"
         />
         <SelectDefault
            placeholder="Сфера проекта"
            onChange={handleSelectSphere}
            arrValues={selectValue}
            value={sphere}
            label="Укажите вашу сферу, а мы определим полезный контент и готовые шаблоны, которые точно вам пригодятся"
            labelStyles={labelStyles}
            labelVariant="overline"
         />
         <SelectDefault
            placeholder="Направление сценария"
            onChange={handleSelectDirection}
            arrValues={selectValue}
            value={direction}
            label="Укажите вашу сферу, а мы определим полезный контент и готовые шаблоны, которые точно вам пригодятся"
            labelStyles={labelStyles}
            labelVariant="overline"
         />
         <SelectDefault
            placeholder="Вовлеченность контактов"
            onChange={handleSelectInvolvement}
            arrValues={selectValue}
            value={involvement}
            label="Cоветуем разделять проекты по типу вовлеченности контактов, которые будут обрабатывать ваши менеджеры"
            labelStyles={labelStyles}
            labelVariant="overline"
         />
         <Box sx={formBoxButtonStyles}>
            <ButtonDefault label="Перейти в редактор" onClick={onClickAddDraft} />
            <ButtonDefault
               backgroundColor={light}
               backgroundColorHover={light}
               color={light900}
               colorHover={light900}
               label="Перейти в редактор"
               onClick={onClickEdit}
            />
         </Box>
      </form>
   );
};
