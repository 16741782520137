import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { LayoutAuth } from 'app/dashboard/layout/layoutAuth';
import { LayoutProfile } from 'app/dashboard/layout/layoutProfile';

import { authRoutes, privateRoutes, publickRoutes } from '../../routes/index';

import useAppSelector from 'app/dashboard/store/hooks/useAppSelector';

const DashboardRouter: React.FC = () => {
   const isAuthenticated = useAppSelector((state) => state.auth.isAuthenticated);

   const routes = isAuthenticated
      ? publickRoutes.concat(privateRoutes)
      : publickRoutes.concat(authRoutes);

   const Layout = isAuthenticated ? LayoutProfile : LayoutAuth;

   return (
      <Routes>
         <Route Component={Layout}>
            {routes.map(({ element, path }) => (
               <Route key={path} path={path} Component={element} />
            ))}
         </Route>
      </Routes>
   );
};

export default DashboardRouter;
