import React from 'react';

import { useComponentForHost } from './hooks/useComponentForHost/index';

import BuildScript from './pages/BuildScript/BuildScript';

import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';

import theme from './styles/theme';

import AppRouters from 'components/AppRouter';

function App() {
   const RouterComponent = useComponentForHost();

   return (
      <div className="App">
         <ThemeProvider theme={theme}>
            <AppRouters />
         </ThemeProvider>
      </div>
   );
}

export default App;
