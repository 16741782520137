import { CSSProperties } from 'react';

export const projectsDataStyles: CSSProperties = {
   width: '100%',
   display: 'flex',
   flexDirection: 'column',
   alignItems: 'center',
   justifyContent: 'space-between',
   gap: '24px',
};

export const projectsDataFilterStyles: CSSProperties = {
   width: '100%',
   display: 'flex',
   alignItems: 'center',
   justifyContent: 'space-between',
   gap: '24px',
};
