import React, { useState } from 'react';

import { ProfileHeader } from '@componentsDashBoard/profileHeader';
import { Box } from '@mui/material';
import { ProjectsNone } from '../projectsNone';
import { ProjectsData } from '../projectData';
import { ColumnDashboard } from '@shared/column';

import { projectsStyles, projectsContentStyles } from './styles';

export const Projects = () => {
   const [isNoneProjects, setIsNoneProjects] = useState<boolean>(false);

   return (
      <ColumnDashboard styles={projectsStyles}>
         <ProfileHeader title="Мои проекты"></ProfileHeader>
         <Box sx={projectsContentStyles({ isNoneProjects })}>
            {isNoneProjects ? <ProjectsNone /> : <ProjectsData />}
         </Box>
      </ColumnDashboard>
   );
};
