import React from 'react';

import { Outlet, Link } from 'react-router-dom';

import { CenteredContainer } from '@shared/containers';
import { useAuth } from 'app/dashboard/hooks/useAuth';

export const LayoutAuth: React.FC = () => {
   const { isLoading } = useAuth();

   return (
      <CenteredContainer>{isLoading ? <p>load...</p> : <Outlet />}</CenteredContainer>
   );
};
