import { CSSProperties } from 'react';

import { colorsUI } from 'constants/colorsUI';
import { SxProps, Theme } from '@mui/material/styles';

const { black600, black800, light900 } = colorsUI;

export const modalDeleteStyles = (theme: Theme): SxProps<Theme> => ({
   width: '100%',
   maxWidth: '676px',
   [theme.breakpoints.down('md')]: {
      maxWidth: 'none',
      margin: 0,
   },
});

export const managersDataFilterStyles: CSSProperties = {
   width: '100%',
   display: 'flex',
   justifyContent: 'start',
};

export const modalDeleteContentStyles: CSSProperties = {
   display: 'flex',
   flexDirection: 'column',
   justifyContent: 'space-between',
   alignItems: 'center',
   gap: '24px',
   width: '100%',
};

export const imageStyles = (theme: Theme) => ({
   width: '100%',
   maxWidth: '160px',
   height: '160px',
   objectFit: 'cover',
   [theme.breakpoints.down('sm')]: {
      maxWidth: '130px',
      height: '130px',
   },
});

export const titleStyles = (theme: Theme) => ({
   fontWeight: 700,
   fontSize: '1.875rem',
   lineHeight: '3rem',
   textTransform: 'uppercase',
   textAlign: 'center',
   color: black600,
   [theme.breakpoints.down('sm')]: {
      fontSize: '1.5rem',
      lineHeight: '2.5rem',
   },
});
export const textStyles: CSSProperties = {
   textAlign: 'center',
   maxWidth: '413px',
   color: black800,
};

export const boxButtonStyles = (theme: Theme): SxProps<Theme> => ({
   width: '100%',
   display: 'flex',
   justifyContent: 'center',
   alignItems: 'center',
   gap: '24px',
   [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      gap: '8px',
   },
});

export const buttonCloseStyles = (theme: Theme): SxProps<Theme> => ({
   border: `1px solid ${light900}`,
   padding: '10px 94px',
   [theme.breakpoints.down('md')]: {
      width: '100%',
   },
});

export const buttonDeleteStyles = (theme: Theme): SxProps<Theme> => ({
   padding: '10px 54px',
   [theme.breakpoints.down('md')]: {
      width: '100%',
   },
});
