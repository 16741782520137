import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import type { TableProps } from '../types';

import {
   tableStyles,
   tableHeaderStyles,
   tableHeaderCellStyles,
   tableCellStyles,
   tableRowCellStyles,
} from '../styles';
import { Typography } from '@mui/material';

export const TableDefault: React.FC<TableProps> = ({ columns, rows }) => {
   return (
      <TableContainer sx={{ ...tableStyles }} component={Paper}>
         <Table sx={tableStyles} aria-label="universal table">
            <TableHead sx={tableHeaderStyles}>
               <TableRow>
                  {columns.map((column) => (
                     <TableCell sx={{ ...tableCellStyles }} key={column.id} align="left">
                        <Typography sx={tableHeaderCellStyles} variant="subtitle2">
                           {column.label}
                        </Typography>
                     </TableCell>
                  ))}
               </TableRow>
            </TableHead>
            <TableBody>
               {rows.map((row, index) => (
                  <TableRow key={index}>
                     {columns.map((column) => (
                        <TableCell sx={tableCellStyles} key={column.id} align="left">
                           {column.renderCell ? (
                              <>{column.renderCell(row)}</>
                           ) : (
                              <Typography variant="body1" sx={tableRowCellStyles}>
                                 {row[column.id] ?? '-'}
                              </Typography>
                           )}
                        </TableCell>
                     ))}
                  </TableRow>
               ))}
            </TableBody>
         </Table>
      </TableContainer>
   );
};
