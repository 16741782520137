import React, { FormEvent, useState, MouseEvent, ChangeEvent } from 'react';

import { useResetPassword } from '../../hooks/useResetPassword';
import useGoBack from 'utils/goBack';

import { Box, Typography } from '@mui/material';
import FormAuth from 'components/forms/formAuth';
import Logo from '@shared/logo';
import { InputDefault } from '@shared/inputs';
import { ButtonDefault } from '@shared/buttons';
import { LinkDefault } from '@shared/links';
import { NotificationMessage } from '../notificationMessage';

import { onSubmitForm as onSubmitFormUtils } from './utils';

import type { ErrorStateResetPassword } from '../../schemas';

import { boxButtonStyles, buttonSubmitStyles } from './style';
import { colorsUI } from '@constants/colorsUI';

const { light900, black300, light500, light600, light, transperent } = colorsUI;

export const ResetPassword = () => {
   const {
      handleResetPassword,
      error: errorResetPassword,
      isError,
      isLoading,
      isSuccess,
   } = useResetPassword();

   const [email, setEmail] = useState('');
   const [error, setErrors] = useState<ErrorStateResetPassword | null>(null);
   const [showNotifications, setShowNotifications] = useState(false);

   const goBack = useGoBack();

   const onSubmitForm = (e: FormEvent) => {
      onSubmitFormUtils({
         e,
         email,
         setEmail,
         setShowNotifications,
         handleResetPassword,
         setErrors,
      });
   };

   const handleChaneElamil = (e: ChangeEvent<HTMLInputElement>) => {
      setEmail(e.target.value);
   };

   return (
      <>
         {showNotifications ? (
            <NotificationMessage
               title="Восстановление пароля"
               label="Введите почту, на которое поступит письмо для восстановления."
            />
         ) : (
            <FormAuth styles={{ maxWidth: '520px' }} onSubmit={onSubmitForm} gap="24px">
               <Logo style={{ width: '100%', maxWidth: '260px' }} />
               <Box sx={{ width: '100%' }}>
                  <Typography sx={{ fontWeight: 700 }} variant="subtitle2">
                     Восстановление пароля
                  </Typography>
                  <Typography sx={{ color: black300, mr: '4px' }} variant="caption">
                     Введите почту, на которое поступит письмо для восстановления.
                  </Typography>
               </Box>
               <Box sx={{ width: '100%' }}>
                  <InputDefault
                     required={true}
                     stylesForm={{ width: '100%' }}
                     value={email}
                     type="email"
                     placeholder="Введите E-Mail"
                     onChange={handleChaneElamil}
                     error={error?.email}
                  />
               </Box>
               <Box sx={boxButtonStyles}>
                  <LinkDefault
                     onClick={goBack}
                     href="/"
                     label="Вернуться назад"
                     color={light600}
                     colorHover={light900}
                     backgroundColor={transperent}
                     backgroundColorHover={transperent}
                  />
                  <ButtonDefault
                     sx={buttonSubmitStyles}
                     disabled={email.length === 0}
                     label="Зарегистрироваться"
                     type="submit"
                     color={light}
                     backgroundColor={light500}
                     backgroundColorHover={light900}
                  />
               </Box>
            </FormAuth>
         )}
      </>
   );
};
