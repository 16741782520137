import { INavigationItemRoutesNavBar } from '../types/navigationItemRoutes';

import { ReactComponent as AccountIcon } from '../../../assets/icons/accountIcon.svg';
import { ReactComponent as SettingsIcon } from '../../../assets/icons/settingsIcon.svg';
import { ReactComponent as Tikets } from '../../../assets/icons/tikets.svg';

export const settingsRoutes: INavigationItemRoutesNavBar[] = [
   {
      path: '/account',
      message: 'аккаунт',
      Icon: AccountIcon,
   },
   {
      path: '/settings',
      message: 'настройки',
      Icon: SettingsIcon,
   },
   {
      path: '/tikets',
      message: 'тикеты',
      Icon: Tikets,
   },
];
