import React, { FC } from 'react';

import { Box, useTheme } from '@mui/material';

import { ReactComponent as IconPlus } from '@assets/icons/plus.svg';
import { ReactComponent as IconDelete } from '@assets/icons/delete.svg';
import { ButtonAction } from '@shared/buttons';

import type { ButtonActionsBoxProps } from './type';

import { buttonActionsBoxStyles, buttonActionsStyles } from './style';
import { colorsUI } from '@constants/colorsUI';
const { danger, dangerActive, transperent } = colorsUI;

export const ButtonActionsBox: FC<ButtonActionsBoxProps> = ({
   onClickDelete,
   onClickAppointManager,
}) => {
   const theme = useTheme();

   return (
      <Box sx={buttonActionsBoxStyles(theme)}>
         <ButtonAction
            label="Назначить менеджера "
            Icon={IconPlus}
            onClick={onClickDelete}
            backgroundColor={transperent}
            backgroundColorHover={transperent}
            color={danger}
            colorHover={dangerActive}
            sx={buttonActionsStyles}
         />
         <ButtonAction
            label="Удалить строку клиента"
            Icon={IconDelete}
            onClick={onClickAppointManager}
            backgroundColor={transperent}
            backgroundColorHover={transperent}
            color={danger}
            colorHover={dangerActive}
            sx={buttonActionsStyles}
         />
      </Box>
   );
};
