import React, { useMemo, useState, MouseEvent, useRef, ElementType, FC } from 'react';

import { useTheme } from '@mui/material';

import { Box, Typography } from '@mui/material';
import { ButtonAction } from '@shared/buttons';
import { ReactComponent as IconFilter } from '@assets/icons/filter.svg';
import { ReactComponent as IconFilterArrow } from '@assets/icons/filterArrow.svg';

import { TableActionsButtonMoreOptions } from 'components/tableActions';
import { PopupDefault } from '@shared/popups';

import type { ClientsFiltersProps, ClientsFiltersPopupContent } from './type';

import {
   inner,
   filterBoxStyles,
   filterButtonStyles,
   actionsButtonMoreOptionsStyles,
   actionsButtonMoreOptionsTitleStyles,
} from './style';

import { colorsUI } from 'constants/colorsUI';
import { ListSelect } from 'shared/lists';
import { InputSearch } from 'shared/inputs';

const { light, light50, light400, light800 } = colorsUI;

export const ClientsFilters: FC<ClientsFiltersProps> = ({ onClickOpenModal }) => {
   const [sortTypeScript, setSortTypeScript] = useState(0);
   const [sortOutcome, setSortOutcome] = useState(0);
   const [sortManager, setSortManager] = useState(0);

   const [popupContent, setPopupContent] = useState<ClientsFiltersPopupContent[]>([]);
   const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
   const [popupIsOpen, setPopupIsOpen] = useState(false);
   const [activeFilter, setActiveFilter] = useState<string | null>(null);

   const theme = useTheme();

   const inputRef = useRef<HTMLInputElement>(null);

   const handleClickPopup = (
      event: MouseEvent<HTMLElement>,
      content: ClientsFiltersPopupContent[],
      filterType: string,
   ) => {
      setAnchorEl(event.currentTarget);
      setPopupContent(content);
      setActiveFilter(filterType);
      setPopupIsOpen(true);
   };

   const handleClosePopup = () => {
      setAnchorEl(null);
      setPopupIsOpen(false);
   };

   const handleSelectChange = (index: number) => {
      if (activeFilter === 'script') {
         setSortTypeScript(index);
      } else if (activeFilter === 'outcome') {
         setSortOutcome(index);
      } else if (activeFilter === 'manager') {
         setSortManager(index);
      }
      handleClosePopup();
   };

   const filterOptions = useMemo(
      () => ({
         script: [
            { label: 'Скрипт 1', Icon: IconFilterArrow },
            { label: 'Скрипт 2', Icon: IconFilterArrow },
            { label: 'Скрипт 3', Icon: IconFilterArrow },
         ],
         outcome: [
            { label: 'Исход 1', Icon: IconFilterArrow },
            { label: 'Исход 2', Icon: IconFilterArrow },
            { label: 'Исход 3', Icon: IconFilterArrow },
         ],
         manager: [
            { label: 'Менеджер 1', Icon: IconFilterArrow },
            { label: 'Менеджер 2', Icon: IconFilterArrow },
            { label: 'Менеджер 3', Icon: IconFilterArrow },
         ],
      }),
      [],
   );

   return (
      <Box sx={inner(theme)}>
         <PopupDefault
            styles={{ padding: 0 }}
            handleClose={handleClosePopup}
            open={popupIsOpen}
            anchorEl={anchorEl}
         >
            <ListSelect
               handleSelectChange={handleSelectChange}
               select={
                  activeFilter === 'script'
                     ? sortTypeScript
                     : activeFilter === 'outcome'
                       ? sortOutcome
                       : sortManager
               }
               items={popupContent}
            />
         </PopupDefault>

         <ButtonAction
            sx={filterButtonStyles(theme) as object}
            backgroundColor={light50}
            backgroundColorHover={light400}
            color={light800}
            colorHover={light}
            Icon={IconFilter}
            label="Фильтр"
            onClick={onClickOpenModal}
         />

         <Box sx={filterBoxStyles(theme)}>
            <Typography>Сортировка по:</Typography>
            <TableActionsButtonMoreOptions
               styles={{ ...actionsButtonMoreOptionsTitleStyles() }}
               stylesButtonMore={actionsButtonMoreOptionsStyles()}
               text="По виду скрипта"
               onClick={(e) =>
                  handleClickPopup(
                     e as MouseEvent<HTMLButtonElement>,
                     filterOptions.script,
                     'script',
                  )
               }
            />
            <TableActionsButtonMoreOptions
               styles={{ ...actionsButtonMoreOptionsTitleStyles() }}
               stylesButtonMore={actionsButtonMoreOptionsStyles()}
               text="По виду исхода"
               onClick={(e) =>
                  handleClickPopup(
                     e as MouseEvent<HTMLButtonElement>,
                     filterOptions.outcome,
                     'outcome',
                  )
               }
            />
            <TableActionsButtonMoreOptions
               styles={{ ...actionsButtonMoreOptionsTitleStyles() }}
               stylesButtonMore={actionsButtonMoreOptionsStyles()}
               text="По менеджеру"
               onClick={(e) =>
                  handleClickPopup(
                     e as MouseEvent<HTMLButtonElement>,
                     filterOptions.manager,
                     'manager',
                  )
               }
            />
            <TableActionsButtonMoreOptions
               styles={{ ...actionsButtonMoreOptionsTitleStyles() }}
               stylesButtonMore={actionsButtonMoreOptionsStyles()}
               text="По виду исхода"
               onClick={(e) =>
                  handleClickPopup(
                     e as MouseEvent<HTMLButtonElement>,
                     filterOptions.outcome,
                     'outcome',
                  )
               }
            />
            <TableActionsButtonMoreOptions
               styles={{ ...actionsButtonMoreOptionsTitleStyles() }}
               stylesButtonMore={actionsButtonMoreOptionsStyles()}
               text="По менеджеру"
               onClick={(e) =>
                  handleClickPopup(
                     e as MouseEvent<HTMLButtonElement>,
                     filterOptions.manager,
                     'manager',
                  )
               }
            />
         </Box>

         <InputSearch inputRef={inputRef} placeholder="Поиск" />
      </Box>
   );
};
