import type { getActiveColorParams } from './type';

import { colorsUI } from 'constants/colorsUI';

const { light, light200 } = colorsUI;

export const getActiveColor = ({
   isActive,
   activeColor = light,
   color = light200,
}: getActiveColorParams): string => (isActive ? activeColor : color);
