import DashBoardMainPage from '../pages/dashboard/DashBoardMainPage';
import DashBoardProjectsPage from '../pages/dashboard/projectsPage/DashBoardProjectsPage';
import DashBoardProjectsCreatePage from '../pages/dashboard/projectsPage/DashBoardProjectsCreatePage';
import DashBoardManagersPage from '../pages/dashboard/managersPage/DashBoardManagersPage';
import DashBoardManegarsPage from '../pages/dashboard/managersPage/DashBoardManagesCreatePage';
import DashBoardClientsPage from '../pages/dashboard/clients/DashBoardClientsPage';

import ResetPasswordPage from '../pages/ResetPasswordPage';
import SignIn from '../pages/SignIn';
import SignUp from '../pages/SignUp';
import NotFound from '../pages/NotFound';

export const privateRoutes = [
   {
      path: '/',
      element: DashBoardMainPage,
   },
   {
      path: '/projects',
      element: DashBoardProjectsPage,
   },
   {
      path: '/projects/create-projects',
      element: DashBoardProjectsCreatePage,
   },
   {
      path: '/managers',
      element: DashBoardManagersPage,
   },
   {
      path: '/managers/create-managers',
      element: DashBoardManegarsPage,
   },
   {
      path: '/clients',
      element: DashBoardClientsPage,
   },
];

export const publickRoutes = [
   {
      path: '*',
      element: NotFound,
   },
];

export const authRoutes = [
   {
      path: '/reset-password',
      element: ResetPasswordPage,
   },

   {
      path: '/Signin',
      element: SignIn,
   },
   {
      path: '/Signup',
      element: SignUp,
   },
];
