import { configureStore } from '@reduxjs/toolkit';

import { setupListeners } from '@reduxjs/toolkit/query';

import authSlice from './slices/auth/authSlice';

import { authApi } from './api/authApi';
import { profileApi } from './api/profileApi';

console.log(authApi.reducerPath);
console.log(profileApi.reducerPath);

const store = configureStore({
   reducer: {
      [profileApi.reducerPath]: profileApi.reducer,
      [authApi.reducerPath]: authApi.reducer,
      auth: authSlice,
   },
   middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(authApi.middleware, profileApi.middleware),
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
